<template>
  <div>
    <BasePageSection
      class="single-location--header column fill-height"
      :bg-image="bgImage.length ? bgImage : false"
      :has-overlay="!!bgImage.length"
      bg-color="#CA6FDA"
      full-bleed-bg
    >
      <VLayout wrap>
        <VFlex xs12 grow>
          <h1
            v-if="title"
            class="page-title header-4 white--text ma-0 text-xs-center"
            >{{ title }}</h1
          >
        </VFlex>
        <VFlex xs12 grow text-xs-center mt-5>
          <BaseButton
            color="bubs-red"
            large
            class="ml-0"
            @click="openOrderDialog"
            >Order Now</BaseButton
          >

          <BaseButtonOutline v-scroll-to="{ el: '#menu', offset: -100 }" large
            >Scroll to Menu</BaseButtonOutline
          >
          <BaseButtonOutline
            v-if="getContactDetail('phone_number')"
            outline
            large
            :href="`tel:${formatPhone(getContactDetail('phone_number'))}`"
            >Call Us</BaseButtonOutline
          >
        </VFlex>
      </VLayout>
    </BasePageSection>
  </div>
</template>

<script>
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'BubsSingleLocationHeader',
  components: {},
  props: {
    bgImage: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('nav', ['isOrderDialogOpen', 'orderDialogSelectedLocation']),
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.location, key)
    }, // getContactDetail
    openOrderDialog() {
      this.orderDialogSelectedLocation = this.location
      this.isOrderDialogOpen = true
    },
  },
}
</script>

<style lang="scss">
@import '@design';

.single-location--header {
  height: 400px;
  min-height: 60vh;
}

.hours--wrap {
  height: auto;
  color: white;
  background-color: $color-bluegiant-blue;
  .container {
    height: 100%;
  }

  @media (min-width: 960px) {
    height: 82px;
  }

  .hours-title--sm {
    font-size: 35px !important;
  }
}
</style>
